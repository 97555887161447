*
    @apply box-border
    -webkit-tap-highlight-color: transparent
    -webkit-touch-callout: none

    &:focus-visible
        @apply outline-4 outline-primary-700 ring-2 ring-primary-100 outline-offset-2 ring-offset-4

html, body
    @apply max-h-screen overflow-hidden
    @apply text-slate-800


img, p:not(.select-text):not(:has(.select-text)), span:not(.select-text):not(:has(.select-text)), h1, h2, h3, a, button
    @apply select-none

.emoji
    @apply inline-block
    height: 1.2em
    width: 1.2em
    margin: 0 .05em 0 .1em    
    vertical-align: -0.15em

.text-balance
    text-wrap: balance
